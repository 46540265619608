
import { useState, useEffect, useMemo, JSXElementConstructor, Key, ReactElement } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate, BrowserRouter as Router } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS exampless
// import Sidenav from "examples/Sidenav";
import Configurator from "components/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";
// import themeDarkRTL from "assets/theme-dark/theme-rtl";


// Material Dashboard 2 PRO React TS routes
import routes from "routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
//import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import brandWhite from "assets/images/smallBlueTransparent.png";//pr_logo_transparent

//import awsconfig from "./aws-exports";
import PrivateRoute from "lib/PrivateRoute";

// Permanent Routes
import SignInCover from "layouts/authentication/sign-in/new-sign";
// import ConfirmationCover from "layouts/authentication/confirmation/index";
import ResetPassword from "layouts/authentication/reset-password/new-reset";
import { AppContext } from "lib/appContext";

import { CacheProvider } from 'lib/CacheContext';
import PropertyTable from "layouts/pages/properties";
import NewProperty from "layouts/pages/properties/new-property";
import EditProperty from "layouts/pages/properties/edit-property";
// import Properties from "layouts/dashboards/properties";
import MYOB from "layouts/pages/account/settings/integration/myob";
import MYOBCallback from "callbacks/myob";
import SignOut from "layouts/authentication/sign-out";
// import DataPage from "layouts/dashboards/properties/DataPage";
// import AccomPage from "layouts/dashboards/accomodation";
// import Reservations from "layouts/dashboards/accomodation/reservations";
import RMSAuth from "callbacks/rms";
import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import ReportsTable from "layouts/pages/reports"
// import NewReport from "layouts/pages/reports/new-report"
// import NewReportTemplate from "layouts/pages/reports/new-report-template"
// import ReportTemplates from "layouts/pages/reports/report-templates"
// import NewSchedReport from "layouts/pages/reports/new-scheduled-report"
// import Report from "layouts/pages/reports/view";
// import ComponentHistory from "layouts/pages/reports/history";
// import TemplateReport from "layouts/pages/reports/view/template";
import { AppContextProvider } from "lib/appContext";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import HorizontalAppBarWithDropdownMenus from "components/Navbars/ResponsiveAppBar";
import Analytics from "layouts/dashboards/analytics/components/embedded";
// import PortfolioReport from "layouts/pages/reports/portfolio"
import XeroCallback from "callbacks/Xero";
import CloudbedsCallBack from "callbacks/Cloudbeds";
import TandaCallBack from "callbacks/Tanda";


export default function App() {

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const [auth, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(true);
  const [urlCode, setUrlCode] = useState("")
  const [tokenData, setTokenData] = useState()
  const [tenantValue, setTenantValue] = useState('');
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element

  return  (
 
    <AppContextProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <CacheProvider>
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      
      <Routes>
        <Route path="/" element={          
            <PrivateRoute>
              {layout === "dashboard" &&(
                <>
                  <HorizontalAppBarWithDropdownMenus
                    color={sidenavColor}
                    brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                    brandName="Peak Revenue"
                    tabs={routes}
                  />
                  <Configurator />
                </>
              )}
              {layout === "vr" && <Configurator />}
            
              </PrivateRoute>}>

            {/* <Route path="/dashboard/accomodation/:propertyId/:companyId/:sd/:ed" element={<AccomPage />} key="accomodation" />
            <Route path="/dashboard/:propertyId/:companyId" element={<DataPage />} key="datapage" /> */}
            <Route path="/properties" element={<PropertyTable key="properties" />} key="properties"/>
            <Route path="/properties/new-property" element={<NewProperty />} key="new-property"/>
            <Route path="/properties/edit-property" element={
              <EditProperty setUrlCode={setUrlCode} urlCode={urlCode} tokenData={tokenData} tenantValue={tenantValue} />} key= "edit-property"/>
            <Route path="/pages/account/settings/integration/myob" element={<MYOB />} key="authentication_myob"/>
            <Route path="/myob/callback" element={<MYOBCallback />} key="myob-callback"/>
            <Route path="/cloudbeds/callback" element={<CloudbedsCallBack   brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                    brandName="Peak Revenue"
                    tabs={routes}
                    urlCode={urlCode}
                    setTokenData={setTokenData}
                    setTenantValue={setTenantValue}
                    tenantValue={tenantValue}/>}  key="cloudbeds-callback"/>
            <Route path="/tanda/callback" element={<TandaCallBack brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                    brandName="Peak Revenue"
                    tabs={routes}
                    urlCode={urlCode}
                    setTokenData={setTokenData}
                    setTenantValue={setTenantValue}
                    tenantValue={tenantValue}/>}  key="tanda-callback"/>
            <Route path="/xero/callback" element={<XeroCallback color={sidenavColor}
                    brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                    brandName="Peak Revenue"
                    tabs={routes}
                    urlCode={urlCode}
                    setTokenData={setTokenData}
                    setTenantValue={setTenantValue}
                    tenantValue={tenantValue}/>} key="xero-callback"/>
            {/* <Route path="/dashboards/properties" element={<Properties />}/> */}
            {/* <Route path="/dashboard/accomodation/reservations" element={<Reservations />} key="property"/> */}
            <Route path="/authentication/sign-out" element={<SignOut />} key="logout"/>
            <Route path="/callbacks/rms" element={<RMSAuth />} key="rmsAuth"/>
            {/* <Route path="/reports" element={<ReportsTable />} key="reports"/>
            <Route path="/reports/new" element={<NewReport />} key="newReport"/>
            <Route path="/reports/sched/new" element={<NewSchedReport />} key="newcChedReport"/>
            <Route path="/reports/view" element={<Report />} key= "viewReport"/>
            <Route path="/reports/view/:componentId/:propertyId" element={<ComponentHistory />} key="viewComponentHistory"/>
            <Route path="/reports/v/t/:companyId/:propertyId/:templateId" element={<TemplateReport />} key="viewTemplateReport"/>
            <Route path="/reports/templates/new" element={<NewReportTemplate />} key="newReportTemplate"/> */}
            {/* <Route path="/reports/templates" element={<ReportTemplates />} key="reportTemplates"/> */}
            <Route path="/analytics/:dashboardId" element={<Analytics />} key="analytics" />
            {/* <Route path="/portfolio" element={<PortfolioReport />} key="portfolio"/> */}
         
          </Route>  
        <Route path="/authentication/sign-in" element={<SignInCover />} key="cover" />;
        <Route path="/authentication/forgot-password" element={<ResetPassword />} key="cover" />;
      </Routes>
    </ThemeProvider>
    </CacheProvider>
    </LocalizationProvider>
    </AppContextProvider>
  );
}

import React, { Dispatch, SetStateAction, useEffect, useState,ReactElement } from 'react'
import {DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup} from '@mui/material'
import MDTypography from 'components/MDTypography'
import { useNavigate, useSearchParams } from 'react-router-dom';
import MDButton from 'components/MDButton'
import { getToken, getXeroTenants } from 'lib/stubs/IntegrationsAPI'
import HorizontalAppBarWithTabsDropdown from 'components/Navbars/ResponsiveAppBar'

interface Props {
    brandName: string;
    tabs: TabItem[];
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
    brand?: string;
    urlCode:string;
    setTokenData:Dispatch<SetStateAction<any>>;
    setTenantValue:Dispatch<SetStateAction<any>>;
    tenantValue:any
  }
  
interface TabItem {
    label: string;
    route: string;
    icon: ReactElement;
    subMenu?: { label: string; route: string }[];
  }

function XeroCallback({ tabs, color, brand,urlCode,setTokenData,setTenantValue,tenantValue }: Props) {
    const [tenantData, setTenantData] = useState([])
    const [searchParams] = useSearchParams();
    const state = searchParams.get("state")
    const propertyID=state.split(':')
    
    const companyID= localStorage.getItem("companyID")
      const navigateProperty=useNavigate()

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTenantValue((event.target as HTMLInputElement).value);
      };
      const handleCloseIntegrationDialog =async () => {
        navigateProperty(`/properties/edit-property?propertyId=${propertyID[1]}&companyId=${companyID}`);
      };
      
      useEffect(()=>{
        const getData=async()=>{
          const token=await getToken(urlCode, "Xero")
         setTokenData(token)
           const Access_token=await getXeroTenants(token.access_token)
         const filteredData = Access_token.filter(item => item.tenantType === "ORGANISATION");
         setTenantData(filteredData)
        }
        getData()
      },[])
      
      return (
        <>
       <HorizontalAppBarWithTabsDropdown
        color={color}
        brand={brand}
        brandName="Peak Revenue"
        tabs={tabs}
      ></HorizontalAppBarWithTabsDropdown>
          <div style={{height: 'calc(100vh - 67px)', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <dialog
              open={true}
              onClose={handleCloseIntegrationDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              style={{margin: '0 auto', border: 'none', borderRadius: '12px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'}}
            >
              <DialogTitle id="alert-dialog-title">
                <MDTypography variant="h3" color="black">
                  Xero Integration
                </MDTypography>
              </DialogTitle>
              <DialogContent>
                <MDTypography variant="h6" paddingBottom="20px">
                  Thank You for authorising access Peak Revenue access to your Xero 
                </MDTypography>
                <MDTypography variant="h6">
                  Please select the Xero Tenant you wish
                  to use for Insights
                </MDTypography>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={tenantValue}
                  onChange={handleChange}
                  style={{paddingTop: '20px', paddingLeft: '20px'}}
                >
                  {tenantData.map((tenantName)=>{
                    return(
                    <>
                      <FormControlLabel value={tenantName.tenantId} control={<Radio />} label={tenantName.tenantName} />
                    </>
                  )})}
                </RadioGroup>
              </DialogContent>
              <DialogActions style={{justifyContent: 'end'}}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleCloseIntegrationDialog}
                  size="small"
                >
                  Continue
                </MDButton>
              </DialogActions>
            </dialog>
          </div>
          
        </>
      );
}

export default XeroCallback
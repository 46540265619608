/**
 * Main Sign-in page
 */

import { SetStateAction, useContext, useEffect, useState } from "react";

// react-router-dom components
import { Link, Route, useNavigate, Router, Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// import dotenv from 'dotenv';
// dotenv.config()

import ReactDOM from "react-dom";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDAvatar from "components/MDAvatar";
import logoMyob from 'assets/images/logos/myob-app.png';
import { CardContent, Divider, Grid, Typography } from "@mui/material";
import ItemSelectionForm from "callbacks/myob/CompanyFIle";
import { getHeaders } from "auth/authService";
import { AppContext, UserData } from 'lib/appContext';

function MYOBCallback(): JSX.Element {

  const [companyFiles, setCompanyFiles] = useState(null)
  //const userData = useContext(AppContext);
  const appContext = useContext(AppContext);
  const userData: UserData | undefined = appContext?.userData;
  const [headers,setHeaders] = useState<HeadersInit>()

  //console.log("Headers:", headers)
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const propertyID = urlParams.get('state');
  const navigate = useNavigate();

  useEffect(() => {

    const fetchData = async () => {
      setHeaders(await getHeaders())
      try {

        const queryParams = `?propertyID=${propertyID}&code=${code}`
        const hdr: HeadersInit = {
          'Accept': 'application/json',
          'Content-Type': 'text/plain',
          'Authorization': `Bearer ${sessionStorage.getItem("accessToken")}`
        }
        
        const response: any  = await fetch(`${process.env.REACT_APP_API_URL}/myob/customerAuth${queryParams}`, { 
          mode: 'cors',
          method: 'get',
          headers: hdr,
        })
        const data = await response.json();
        console.log("Response: ", data)
        setCompanyFiles(data)
        
      } catch (error) {
        console.error('Access Token Error', error);
        return error;
      }

    };
  
    fetchData(); 
  }, []);

  const handleSubmit = async (selectedItem: any) => {
    // Call your API endpoint here using the selected item
    console.log('Selected item:', selectedItem);

    // Update myob Integration /myob/myobIntegration
    try{
      const result = JSON.parse(selectedItem.selectedOption)
      const body = {
        "companyFileURI": result.uri,
        "companyFileName": result.name,
        "propertyID": propertyID
      }
      console.log("body:", body)
      const hdrs = await getHeaders()
      const response: any  = await fetch(`${process.env.REACT_APP_API_URL}/myob/myobIntegration`, { 
        mode: 'cors',
        method: 'post',
        headers: hdrs,
        body: JSON.stringify(body)
      })
      
      //return to properties page
     
      navigate(`/properties/edit-property?propertyId=${propertyID}&companyId=${sessionStorage.getItem("company_id")}`);
      

    }catch(error){
      console.log("Error saving myobIntegration: ", error )
    }
  };

  return (
    <DashboardLayout>
      <Card >
        <CardContent>
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item>
                <MDBox display="flex" alignItems="center">
                  <MDAvatar src={logoMyob} alt="MYOB logo" variant="rounded" />
                </MDBox>
              </Grid>
              <Grid item>
                <MDTypography color="dark" my={1}>
                  Your MYOB Account has been authorised
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <Typography variant="body2" color="textSecondary" component="p">
            Thank you for authorising Peak Revenue access to your MYOB Company file. 
          </Typography>
          <Divider/>
          <Typography variant="body2" color="textSecondary" component="p">
            Over the next 24 hours data will be synchronised between Peak Reveune and MYOB.  
            12 months of historical data will be imported and once all data is loaded 
            the property will be enabled. 
          </Typography>
          <Divider/>
          <Typography variant="body2" color="textSecondary" >
            Please select which Company File you want to use for this Property
          </Typography>
          <MDBox>
          <div>
            {companyFiles !== null && <ItemSelectionForm items={companyFiles} onSubmit={handleSubmit} />}
          </div>
          </MDBox>
        </CardContent>
      </Card>   
    </DashboardLayout>
  );
}

export default MYOBCallback;

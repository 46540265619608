import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import MDButton from 'components/MDButton';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { useState } from 'react';


interface Item {
  accountingDate: string,
  code: string,
  clientId: number,
  timeZone: string,
  useSecondaryCurrency: string,
  id: number,
  name: string,
  inactive: boolean
}

const initialValues = {
    selectedOption: ''
  };

interface Props {
  items: Item[];
  onSubmit: (selectedItem: string) => void;
}

function ItemSelectionForm({ items, onSubmit }: any): JSX.Element {

  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  return (
    <div>
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <Field name="selectedOption">
          {({ field }: FieldProps<string>)=> (
            <RadioGroup {...field}>
              {items.map((item: Item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.id}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </RadioGroup>
          )}
        </Field>
        <MDButton 
          variant="gradient" 
          color="dark" 
          size="small"
          type='submit'
          >
          Select RMS Property
        </MDButton>
      </Form>
    </Formik>
    </div>
  );
};

export default ItemSelectionForm;

import { DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { Field, FormikProvider, useFormik } from 'formik'
import cancelIcon from 'assets/images/cancelIcon.svg';
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import arrowDropDown from 'assets/images/arrowDropDown.svg';

function XeroConfiguration() {
  const [openField, setOpenField] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false)
  const [payPeriod, setPayPeriod] = useState("");
  const [weekDay, setWeekDay] = useState("");
  const navigate =useNavigate()
  const [searchParams] = useSearchParams();
  const companyID = searchParams.get("companyId")
  const propertyID = searchParams.get("propertyId")
  const [isArrowDownPayPeriod, setIsArrowDownPayPeriod] = useState(false);
  const [isArrowDownWeekDay, setIsArrowDownWeekDay] = useState(false);

  const handleCloseConfigDialog = () => {
    navigate(`/properties/edit-property?propertyId=${propertyID}&companyId=${companyID}`);
  };
  
  const IntegrationConfigurationForm=useFormik<any>({
    enableReinitialize: true,
    initialValues:{
      HouseKeeping:'',
      Reception:'',
      Maintenance:'',
      Accommodation:'',
    },
    onSubmit:values =>{
      console.log(values,"values")
    }
  })

  const RemoveValue=async(value:string)=>{
    IntegrationConfigurationForm.setFieldValue(value,''),
    setIsOpen(false)
  }
//handle particluar input click
  const handleEvent =(fieldName: string) => {
  if (openField === fieldName) {
    setIsOpen(true) // Close the currently open field
  } else {
   setOpenField(fieldName);
    setIsOpen(true) // Set the clicked field as open
   }
 };
  const handleChangePayPeriod = (event: SelectChangeEvent) => {
  setPayPeriod(event.target.value);
 };
  const handleChangeWeekDay = (event: SelectChangeEvent) => {
  setWeekDay(event.target.value);
 };
  return (
    <div style={{height: 'calc(100vh - 67px)', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
         <dialog
          open={true}
          onClose={handleCloseConfigDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{margin: '0 auto', border: 'none', borderRadius: '12px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'}}
        >
          <DialogTitle id="alert-dialog-title">              
           <MDBox py={2} style={{fontSize: '30px', fontWeight: '700'}}>Xero Configuration</MDBox>
            <MDBox style={{fontSize: '14px', fontWeight: '400', marginBottom: '20px'}}>To configure your Xero integration we need to know how your Tenant is set up.</MDBox>
          </DialogTitle>
            <DialogContent>
              <FormikProvider value={IntegrationConfigurationForm}>
                <MDBox>
                  <Grid container spacing={6} >
                    <Grid item xl={5} md={6} xs={12} position="relative">
                      <MDBox
                        py={2}
                        style={{
                          height: "100%",
                          background: "#f5f5f5",
                          border: "1px solid #ebe9ed",
                          borderRadius: "12px",
                          padding: "16px",
                        }}
                      >
                          <div style={{ fontWeight: "600" }}>General Ledger</div>
                            <div
                                  style={{
                                    fontSize: "14px",
                                    paddingBottom: "20px",
                                  }}
                                >
                                  The following GL Codes are required to analyze your property expenses
                            </div>
                              <div style={{ position: "relative", paddingBottom: '20px' }}>
                                <Field name="HouseKeeping">
                                  {({field}:any)=>(
                                    <>
                                      <MDInput  value={IntegrationConfigurationForm.values.HouseKeeping} onChange={(e:any)=>IntegrationConfigurationForm. setFieldValue('HouseKeeping',e.target.value) } onClick={()=>handleEvent('HouseKeeping')} id="filled-basic2"  label="HouseKeeping GL Code" variant="filled" style={{width: '100%'}}/>
                                        {openField === 'HouseKeeping' && isOpen && (
                                          <img
                                          src={cancelIcon}
                                          onClick={()=>RemoveValue('HouseKeeping')}
                                          alt="cancelIcon"
                                          style={{ position: 'absolute', top: '20px', right: '10px', zIndex: '9', cursor: 'pointer' }}
                                          />
                                        )}
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div style={{ position: "relative", paddingBottom: '20px' }}>
                                <Field name="Reception">
                                  {({field}:any)=>(
                                     <>
                                        <MDInput  value={IntegrationConfigurationForm.values.Reception} onChange={(e:any)=>IntegrationConfigurationForm.setFieldValue('Reception',e.target.value) } onClick={()=>handleEvent('Reception')} id="filled-basic2" label="Reception GL Code" variant="filled" style={{width: '100%'}}/>
                                          {openField === 'Reception'&& isOpen && (
                                            <img
                                              src={cancelIcon}
                                              onClick={()=>RemoveValue('Reception')}
                                              alt="cancelIcon"
                                              style={{ position: 'absolute', top: '20px', right: '10px', zIndex: '9', cursor: 'pointer' }}
                                            />
                                          )}
                                      </>
                                    )}
                                </Field>
                              </div>
                              <div style={{ position: "relative", paddingBottom: '20px' }}>
                                <Field name="Maintenance" >
                                  {({field}:any)=>(
                                     <>
                                       <MDInput  value={IntegrationConfigurationForm.values.Maintenance} onChange={(e:any)=>IntegrationConfigurationForm.setFieldValue('Maintenance',e.target.value) } onClick={()=>handleEvent('Maintenance')} id="filled-basic2" label="Maintenance GL Code" variant="filled" style={{width: '100%'}}/>
                                         {openField === 'Maintenance' && isOpen && (
                                            <img
                                            src={cancelIcon}
                                            onClick={()=>RemoveValue('Maintenance')}
                                            alt="cancelIcon"
                                            style={{ position: 'absolute', top: '20px', right: '10px', zIndex: '9', cursor: 'pointer' }}
                                            />
                                         )}
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div style={{ position: "relative", paddingBottom: '20px' }}>
                                <Field name="Accommodation" >
                                  {({field}:any)=>(
                                    <>
                                      <MDInput  value={IntegrationConfigurationForm.values.Accommodation} onChange={(e:any)=>IntegrationConfigurationForm.setFieldValue('Accommodation',e.target.value) } onClick={()=>handleEvent('Accommodation')} id="filled-basic2" label="Accommodation GL Code" variant="filled" style={{width: '100%'}}/>
                                        {openField === 'Accommodation' && isOpen && (
                                          <img
                                            src={cancelIcon}
                                            onClick={()=>RemoveValue('Accommodation')}
                                            alt="cancelIcon"
                                            style={{ position: 'absolute', top: '20px', right: '10px', zIndex: '9', cursor: 'pointer' }}
                                          />
                                        )}
                                     </>  
                                   )}
                                </Field>
                              </div>
                        </MDBox>
                    </Grid>
                    <Grid item xl={7} md={6} xs={12} style={{position: 'relative'}}>
                        <MDBox
                          py={2}
                          style={{
                            height: "100%",
                            background: "#f5f5f5",
                            border: "1px solid #ebe9ed",
                            borderRadius: "12px",
                            padding: "16px",
                          }}
                        >
                          <div style={{ fontWeight: "600" }}>Payroll</div>
                            <div
                                style={{
                                  fontSize: "14px",
                                  paddingBottom: "20px",
                                }}
                              >
                                To accurately capture payroll data please select
                                the pay period and day of the week that payroll is
                                processed for this property
                            </div>
                              <Grid container spacing={3}>
                                <Grid item md={6} xs={12} position="relative">
                                  <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label">
                                      Pay Period
                                    </InputLabel>
                                      <img
                                        src={arrowDropDown}
                                        alt="arrowDropDown"
                                        style={{
                                          position: "absolute",
                                          right: '10px',
                                          top: '15px',
                                          transform: isArrowDownPayPeriod ? "rotate(180deg)" : "rotate(0deg)",
                                        }}
                                      />
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={payPeriod}
                                      label="Pay Period"
                                      onChange={handleChangePayPeriod}
                                      onOpen={() => setIsArrowDownPayPeriod(true)}
                                      onClose={() => setIsArrowDownPayPeriod(false)}
                                      style={{ height: "56px"}}
                                      MenuProps={{ PaperProps: { sx: {padding: "0", background: "#D9D9D9 !important", boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" }, }, }}
                                    >
                                      <MenuItem value={1} style={{height: '56px', paddingLeft: '48px', color: '#000'}}>Weekly</MenuItem>
                                      <MenuItem value={2} style={{height: '56px', paddingLeft: '48px', color: '#000'}}>Fortnightly</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12} position="relative">
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Day of Week
                                    </InputLabel>
                                    <img
                                        src={arrowDropDown}
                                        alt="arrowDropDown"
                                        style={{
                                          position: "absolute",
                                          right: '10px',
                                          top: '15px',
                                          transform: isArrowDownWeekDay ? "rotate(180deg)" : "rotate(0deg)",
                                        }}
                                      />
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={weekDay}
                                      label="Day of Week"
                                      onChange={handleChangeWeekDay}
                                      onOpen={() => setIsArrowDownWeekDay(true)}
                                      onClose={() => setIsArrowDownWeekDay(false)}
                                      style={{ height: "56px" }}
                                      MenuProps={{ PaperProps: { sx: {padding: "0", background: "#D9D9D9 !important", boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" }, }, }}
                                    >
                                      <MenuItem value={10} style={{height: '56px', paddingLeft: '48px', color: '#000'}}>Monday</MenuItem>
                                      <MenuItem value={20} style={{height: '56px', paddingLeft: '48px', color: '#000'}}>Tuesday</MenuItem>
                                      <MenuItem value={30} style={{height: '56px', paddingLeft: '48px', color: '#000'}}>Wednesday</MenuItem>
                                      <MenuItem value={40} style={{height: '56px', paddingLeft: '48px', color: '#000'}}>Thursday</MenuItem>
                                      <MenuItem value={50} style={{height: '56px', paddingLeft: '48px', color: '#000'}}>Friday</MenuItem>
                                      <MenuItem value={60} style={{height: '56px', paddingLeft: '48px', color: '#000'}}>Saturday</MenuItem>
                                      <MenuItem value={70} style={{height: '56px', paddingLeft: '48px', color: '#000'}}>Sunday</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </MDBox>
                      </Grid>
                  </Grid>                  
                </MDBox>
              </FormikProvider>
            </DialogContent>
              <DialogActions style={{justifyContent: 'center',marginTop: '50px'}}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleCloseConfigDialog}
                  size="small"
                >
                  Save
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleCloseConfigDialog}
                  size="small"
                >
                  Cancel
                </MDButton>
              </DialogActions>
         </dialog>
       </div>
  )
}

export default XeroConfiguration
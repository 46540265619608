/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "layouts/pages/properties/new-property/schemas/form";

const {
  formField: { propertyName, propertyDescription, abn, rooms },
} = checkout;

const validations = [
  Yup.object().shape({
    [propertyName.name]: Yup.string().required(propertyName.errorMsg),
    [propertyDescription.name]: Yup.string().required(propertyDescription.errorMsg),
    [abn.name]: Yup.string().required(abn.errorMsg),
    [rooms.name]: Yup.string().required(rooms.errorMsg)
  }),
  
];

export default validations;

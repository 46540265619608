// IntegrationsAPI.ts

export interface Integration {
  id: string;
  propertyId: string;
  type: string;
  name: string;
  icon: string;
}

export type AvailableIntegration = {
  id: string,
  name: string,
  type: string,
  url: string
}

export interface IntegrationSecrets {
  clientId: string;
  clientSecret: string;
}

export interface XeroTenant {
  propertyId: string;
  integrationId: string; 
  type: string;
  name: string;
  token: string;
  externalId: string;
}

export class IntegrationsAPI {
  private baseUrl: string;

  constructor() {
    this.baseUrl = "http:localhost:3001";
  }

  async savePropertyIntegration(
    propertyId: string,
    type: string,
    name: string,
    secretValue: string,
    externalId: string
  ): Promise<{ message: string }> {
    // Simulating API call with dummy data
    console.log('Saving property integration:', { propertyId, type, name, externalId });
    return { message: 'Integration saved successfully' };
  }

  async getPropertyIntegrations(propertyId: string): Promise<Integration[]> {
    // Simulating API call with dummy data
    console.log('Getting property integrations for:', propertyId);
    return [
      {
        "id": "786a68dc-e4ec-4493-8ea8-da12955c215d",
        "propertyId": `${propertyId}`,
        "type": "ts",
        "name": "Tanda",
        "icon": "tanda.jpg"
      },
      {
        "id": "874a68dc-e4ec-4493-8ea8-da12955c215d",
        "propertyId": `${propertyId}`,
        "type": "pms",
        "name": "RMS",
        "icon": "rms.jpg"
      }
    ];
  }

  async deletePropertyIntegration(integrationId: string): Promise<{ message: string }> {
    // Simulating API call with dummy data
    console.log('Deleting property integration:', integrationId);
    return { message: 'Integration deleted successfully' };
  }

  async getIntegrationSecrets(integrationId: string): Promise<IntegrationSecrets> {
    // Simulating API call with dummy data
    console.log('Getting integration secrets for:', integrationId);
    return {
      clientId: "239847324",
      clientSecret: "lkjasfljlkasjflkajs"
    };
  }

  async getAvailableIntegrations(integrationType: string, propertyId: string): Promise<AvailableIntegration[]> {
    // Simulating API call with dummy data
    console.log('Getting available integrations');

    //TODO: only return the integrations based on the integration type

    return [
      {
        id: "XERO-8923794797324732974",
        name: "Xero",
        type: "acc",
        url: `http://localhost:3000/callbacks/xero?code=1234567890&state=1234567890:${propertyId}`
      },
      {
        id: "MYOB-UIYOIUWE987R92343243",
        name: "MYOB",
        type: "acc",
        url: `http://localhost:3000/callbacks/myob?code=1234567890&state=1234567890:${propertyId}`
      },
      {
        id: "CLOUDBEDS-980988088809899",
        name: "Cloudbeds",
        type: "pms",
        url: `http://localhost:3000/callbacks/cloudbeds?code=1234567890&state=1234567890:${propertyId}`
      },{
        id: "NEWBOOK-980988088809899",
        name: "Newbook",
        type: "pms",
        url: `http://localhost:3000/callbacks/newbook?code=1234567890&state=1234567890:${propertyId}`
      },
      {
        id: "DEPUTY-980988088809899",
        name: "Deputy",
        type: "ts",
        url: `http://localhost:3000/callbacks/deputy?code=1234567890&state=1234567890:${propertyId}`
      }
    ];
  } 
}

 export async function getToken(code: string, integration: string): Promise<any> {
  return {
    access_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c",
    id_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZW1haWwiOiJqb2huLmRvZUBleGFtcGxlLmNvbSIsImlhdCI6MTUxNjIzOTAyMn0.7eMe1dyoP4Ek7Zy7TZkZh-XRPkpzKBjnGK4VdYkewwM",
    expires_in: 3600,
    token_type: "Bearer",
    refresh_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwicmVmcmVzaCI6dHJ1ZSwiaWF0IjoxNTE2MjM5MDIyfQ.7UX6Vp9-Tf9-Qp8TjW8lBDNHPdWYV6DvPXHoWZPzNM4"
  };
}

export async function getCloudbedsHotels(access_token: string): Promise<any> {
  return [
    {
        "propertyID": "276",
        "organizationID": "234",
        "propertyName": "Test Property",
        "propertyImage": "http:\/\/test.cloudbeds.com\/image.jpg",
        "propertyDescription": "",
        "propertyTimezone": "Australia/Sydney",
        "propertyCurrency": {
            "currencyCode": "AUD",
            "currencySymbol": "$",
            "currencyPosition": "before"
        }
    },
    {
      "propertyID": "672",
      "organizationID": "234",
      "propertyName": "Test Property 2",
      "propertyImage": "http:\/\/test.cloudbeds.com\/image.jpg",
      "propertyDescription": "",
      "propertyTimezone": "Australia/Sydney",
      "propertyCurrency": {
          "currencyCode": "AUD",
          "currencySymbol": "$",
          "currencyPosition": "before"
      }
  }
  ]
}

export async function getXeroTenants(access_token: string): Promise<any[]> {
  return [
    {
        id: "e1eede29-f875-4a5d-8470-17f6a29a88b1",
        authEventId: "d99ecdfe-391d-43d2-b834-17636ba90e8d",
        tenantId: "70784a63-d24b-46a9-a4db-0e70a274b056",
        tenantType: "ORGANISATION",
        tenantName: "Maple Florist",
        createdDateUtc: "2019-07-09T23:40:30.1833130",
        "updatedDateUtc": "2020-05-15T01:35:13.8491980"
    },
    {
        "id": "32587c85-a9b3-4306-ac30-b416e8f2c841",
        "authEventId": "d0ddcf81-f942-4f4d-b3c7-f98045204db4",
        "tenantId": "e0da6937-de07-4a14-adee-37abfac298ce",
        "tenantType": "ORGANISATION",
        "tenantName": "Adam Demo Company (NZ)",
        "createdDateUtc": "2020-03-23T02:24:22.2328510",
        "updatedDateUtc": "2020-05-13T09:43:40.7689720"
    },
    {
        "id": "74305bf3-12e0-45e2-8dc8-e3ec73e3b1f9",
        "authEventId": "d0ddcf81-f942-4f4d-b3c7-f98045204db4",
        "tenantId": "c3d5e782-2153-4cda-bdb4-cec791ceb90d",
        "tenantType": "PRACTICEMANAGER",
        "tenantName": null,
        "createdDateUtc": "2020-01-30T01:33:36.2717380",
        updatedDateUtc: "2020-02-02T19:21:08.5739590"
    }
  ]
}

export async function saveXeroTenant(tenant: XeroTenant): Promise<boolean> {
  return true;
}


// Usage example:
// const api = new IntegrationsAPI();
// await api.savePropertyIntegration('property123', 'type', 'name', 'secretValue', 'externalId');
// const integrations = await api.getPropertyIntegrations('property123');
// await api.deletePropertyIntegration('integration123');
// const secrets = await api.getIntegrationSecrets('integration123');
// const availableIntegrations = await api.getAvailableIntegrations('acc', 'property123');
// const token = await api.getToken('code');
// const tenants = await api.getXeroTenants('access_token');
// await api.saveXeroTenant({ propertyId: 'property123', integrationId: 'integration123', type: 'acc', name: 'Xero', token: 'token123', externalId: 'externalId123' });
// 


import { Dispatch, SetStateAction, useEffect, useState } from "react";
// formik components
import { Formik, Form } from "formik";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Footer from "components/Footer";

// Edit Property page components
// NewUser layout schemas for form and form feilds
//import validations from "layouts/dashboards/properties/edit-property/schemas/validations";
import form from "./schemas/form";
//import initialValues from "layouts/dashboards/properties/edit-property/schemas/initialValues";
import MDTypography from "components/MDTypography";
import FormField from "./components/FormField";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useFormik, Field, FormikProvider } from 'formik';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Switch, TextField} from '@mui/material';
import {FormControlLabel} from '@mui/material';
import MyobImage from 'assets/images/myob-logo-vector.png';
import logoRMS from 'assets/images/rms-logo.svg';
import logoMyob from 'assets/images/small-logos/myob-logo-vector.png';
import Divider from "@mui/material/Divider";
import Icon from "assets/theme/components/icon";
import MDAvatar from "components/MDAvatar";
import { getHeaders } from "auth/authService";
import { ForkLeft, Image } from "@mui/icons-material";
import { ComponentType } from "lib/componentType";
import { useForm } from 'react-hook-form';
import { lightBlue } from "@mui/material/colors";
import CircularProgress from '@mui/material/CircularProgress';
import { Integration, IntegrationsAPI, saveXeroTenant } from "lib/stubs/IntegrationsAPI";
import { PropertyAPI } from "lib/stubs/PropertyAPI";
import HorizontalAppBarWithTabsDropdown from "components/Navbars/ResponsiveAppBar";
import MDInput from "components/MDInput";
import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import XeroConfiguration from "layouts/pages/configuration-page";

interface FormValues {
  [key: string]: string;
}



function isObjectEmpty(obj: Record<string, any>): boolean {
  return Object.keys(obj).length === 0;
}
interface Props {
  setUrlCode:Dispatch<SetStateAction<string>>;
  tokenData:any;
  tenantValue:any;
  urlCode:any;
}

function EditProperty({setUrlCode,tokenData,tenantValue,urlCode }:Props): JSX.Element {
  const [property, setProperty] = useState<any>(false)
  const [MYOBintegration, setMYOBIntegration] = useState<any>({})
  const [RMSintegration, setRMSIntegration] = useState<any>({})
  const [searchParams] = useSearchParams();
  const companyID = searchParams.get("companyId")
  const propertyID = searchParams.get("propertyId")
  const [configData, setConfigData] = useState<any>(null)

  const [buttonText, setButtonText] = useState("Update")
  const [configButtonText, setConfigButtonText] = useState("Update")
  const [headers,setHeaders] = useState<HeadersInit>(null)
  const [isLoading, setIsLoading] = useState(true);
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [componentData, setComponentData] = useState<string[]>([])
  const { register, handleSubmit, reset, setValue } = useForm<FormValues>();
  const [historyButtonText, setHistoryButtonText] = useState("Initialise")
  const [error, setError] = useState<string>("")
  const[buttonName , setButtonName]=useState<any>('')

  useEffect(() => {
    
    const setup =async () => {
      const hdrs = await getHeaders()
      setHeaders(hdrs)
    }
    setIsLoading(true)
    setup()
    setIsLoading(false)
  },[])

  const { formId, formField } = form;
  //const currentValidation = validations[0];
  const navigate = useNavigate();

  const sleep = (ms: any) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

 //Retrieve Property Details from API
  useEffect(() => {
    if(headers!==null){
      getPropertyDetails(propertyID,companyID)
    }
  },[headers])

  const getPropertyDetails = async (propertyID:string, companyID:string) => {
    console.log("propertyID:", propertyID)
    try {
      
      const body = JSON.stringify(
        {
          "propertyID": propertyID,
          "companyID": companyID,
        })

      const queryParams = `?propertyId=${propertyID}&companyId=${companyID}`
      
      const response: any  = await fetch(`${process.env.REACT_APP_API_URL}/property${queryParams}`, { 
        mode: 'cors',
        method: 'get',
        headers: headers,
      })
      const data = await response.json();
      setProperty(data)
      setInitialSwitchValue(data.enabled)

      //Retrieve any existing myobIntegrations
      const responseIntegration: any = await fetch(`${process.env.REACT_APP_API_URL}/myob/myobIntegration/${propertyID}`,{
        mode: 'cors',
        method: 'get',
        headers: headers,
      })
      const intData = await responseIntegration.json()
 
      setMYOBIntegration(intData)

      //retrieve any RMS integrations
      const rmsBody = JSON.stringify({
        PRPropertyId: propertyID
      })
      const rmsIntegration: any = await fetch(`${process.env.REACT_APP_API_URL}/rms/rmsIntegration`,{
        mode: 'cors',
        method: 'post',
        headers: headers,
        body: rmsBody
      })
      const rmsIntegrationData = await rmsIntegration.json()
 
      setRMSIntegration(rmsIntegrationData)      

      //Load Components to extract the Config Items
      const responseComponents: any = await fetch(`${process.env.REACT_APP_API_URL}/components`,{
        mode: 'cors',
        method: 'get',
        headers: headers,
      })
      const componentData = await responseComponents.json()
      console.log("Components", componentData)
 
      setComponentData(componentData)

      //Load property Config items
      const responseConfig: any = await fetch(`${process.env.REACT_APP_API_URL}/config/${propertyID}`,{
        mode: 'cors',
        method: 'get',
        headers: headers,
      })

      const configData = await responseConfig.json()
      console.log("Config", configData)
      setConfigData(configData.config)


    } catch (error) {
      console.log("Error retreiving Property", error)
    }
  }

  useEffect(() => {
    if (configData != null) {
      Object.keys(configData).forEach(function (key) {
        console.log('key:', key);
        console.log('value:', configData[key]);
        setValue(configData[key].key, configData[key].value );
      });
    }
  }, [configData]);

  // Save property details
  const updateProperty = async (values: any) => {

    setButtonText("Updating...")
    //console.log("Updating property:", values)
    const body = JSON.stringify(
    {
      "propertyId": propertyID,
      "companyId": companyID,
      "description": values.propertyDescription,
      "name": values.propertyName,
      "enabled": values.propertyEnabled,
      "myob": property.myob,
      "rms": property.rms,
      "abn": values.abn,
      "rooms": values.rooms,
      "dataDay": values.dataDay
    })
    //console.log("body:",body)
    //const headers = await getHeaders();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/property`, { 
      mode: 'cors',
      method: 'post',
      body: body,
      headers: headers,
    })

    //console.log("response:", response)
    await sleep(2000);
 
    setButtonText("Update")

  };

  const initialValues = {
    propertyName: property.name,
    propertyDescription: property.description,
  }
  const [initialSwitchValue, setInitialSwitchValue] = useState<boolean>(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      propertyName: property.name ? property.name : "",
      propertyDescription: property.description? property.description : "",
      propertyEnabled: initialSwitchValue,
      abn: property.abn? property.abn : "",
      rooms: property.rooms? property.rooms : "",
      dataDay: property.dataDay? property.dataDay : "0",
    },
    onSubmit: values => {
      updateProperty(values)
    },
  });

  const [enabledValue, setEnabledValue] = useState(property.enabled ? property.enabled : false );
  const handleEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = !e.target.checked;
    property.enabled = newValue
    setEnabledValue(newValue);
  };
  
  // const [myobEnabled, setMyobEnabled] = useState<boolean>(false)

  function handleMYOBChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    throw new Error("Function not implemented.");
  }

  // const [rmsEnabled, setRmsEnabled] = useState<boolean>(false)

  function handleRMSChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    throw new Error("Function not implemented.");
  }

  const MYOB_API_KEY = process.env.REACT_APP_MYOB_API_KEY
  const MYOB_API_REDIRECT_URI = encodeURIComponent(`${process.env.REACT_APP_CALLBACK_URL}`)//encodeURIComponent(process.env.MYOB_API_REDIRECT_URI)
  const myobAuth = 
    `https://secure.myob.com/oauth2/account/authorize?client_id=${MYOB_API_KEY}&redirect_uri=${MYOB_API_REDIRECT_URI}&response_type=code&scope=CompanyFile&state=${propertyID}`

  function authRMS() {
    //Navigate to RMS auth page
    navigate(`/callbacks/rms?propertyId=${propertyID}`);
  }

  //handle the click of the config button
  const onSubmit = (data: FormValues) => {
    
    //Save the config data to dynamoDB
    saveConfigData(data)
  };

  //Save the config data to dynamoDB
  const saveConfigData = async (data: FormValues) => {
    setConfigButtonText("Saving...")
    const body = JSON.stringify(
      {
        "pid": propertyID,
        "config": data
      })
      //console.log("body:",body)
      const headers = await getHeaders();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/config`, {
        mode: 'cors',
        method: 'post',
        body: body,
        headers: headers,
      })
      console.log("response:", response)
      await sleep(2000);
      setConfigButtonText("Update")
  }

  //THis function initialises the component history
  function refreshHistory(componentID:string): void {
    
    //setHistoryButtonText("Initialising...") //TODO: Make unique for each component
    setIsLoading(true);

    const resetHistory = async (componentID:string) => {

      try {

        const headers = await getHeaders()
        
        //refresh Component History
        const body = JSON.stringify(
          {
            "companyId": sessionStorage.getItem("company_id"),
            "propertyId": propertyID,
            "componentId": componentID,
            "numberOfWeeks": 12,
          })
        const response = await fetch(`${process.env.REACT_APP_API_URL}/inithistory`, {
          mode: 'cors',
          method: 'POST', 
          headers: headers,
          body: body
        })
        const data = await response.json();
        console.log("history:", data)

        if(data.errors){
          setError("There was an issue generating component History")
          throw new Error(data.errors);
        }
      } catch (error) {
        console.error("Error:", error)
      }
      setIsLoading(false);
      setHistoryButtonText("Refresh History")
    }
    resetHistory(componentID)
  }
 // create function for set integrations data to integrations
 async function getIntegrations() {
  const api = new IntegrationsAPI();
  const integrationsData:Integration[] = await api.getPropertyIntegrations('property123');
  setIntegrations(integrationsData);
}

useEffect(()=>{
  // call function on page call
   getIntegrations()
},[])

const [openConfigDialog, setOpenConfigDialog] = useState(false);

const handleClickOpenConfigDialog = async() => {
  setOpenConfigDialog(true)
  navigate(`/properties/edit-property?propertyId=${propertyID}&companyId=${companyID}&xeroconfiguration=true`);
};

const handleCloseConfigDialog = () => {
  navigate(`/properties/edit-property?propertyId=${propertyID}&companyId=${companyID}`);
};
const [openAccountingSystemDialog, setAccountingSystemDialog] = useState(false);
const [addAccountingSystemDialog, setAddAccountingSystemDialog] = useState(false);
const [isAuthDialog, setIsAuthDialog] = useState(false);
const[accountingSystemButtons,setAccountingSystemButtons]=useState([])
const [integrationId, setIntegrationId] = useState(localStorage.getItem('IntegrationId'))

const handleClickAccountingSystemDialog = async(type:string,title:string) => {
  setButtonName(title)
  setAccountingSystemDialog(true);
  const api = new IntegrationsAPI();
  const availableIntegrations =await api.getAvailableIntegrations('acc', 'property123');    
  const ButtonsType=availableIntegrations.filter((buttonName)=>buttonName.type === type)
  setAccountingSystemButtons(ButtonsType)
};

const handleOpenXeroDialog=(url:any,buttonName:any)=>{
setButtonName(buttonName)
 const urlObj = new URL(url);
 // Get query parameters using URLSearchParams
 const params = new URLSearchParams(urlObj.search);
 // Extract the individual parameters
 const code = params.get('code');    
 const state=params.get('state')
 const IntegrationStateId=state.split(':')
 const Data=localStorage.setItem('IntegrationId',IntegrationStateId[0] as any)
 console.log('Data: ', Data);
 setUrlCode(code)
 setIntegrationId(IntegrationStateId[0])
 setAddAccountingSystemDialog(true)
 setAccountingSystemDialog(false);
 localStorage.setItem('buttonNameInfo',JSON.stringify(buttonName))
}

//This needs to be changed to handle the Xero Auth
const handleOpenXeroAuthDialog=async(name:any)=>{
setIsAuthDialog(true);
setAddAccountingSystemDialog(false);
localStorage.setItem("companyID",companyID)
await setTimeout(async ()=>{
  if(name==="Xero"){
    window.open(`/xero/callback?code=${urlCode}&state=${integrationId}:${propertyID}`, '_blank', 'noreferrer');
  }
  else if(name==="Cloudbeds"){
    window.open(`/cloudbeds/callback?code=${urlCode}&state=${integrationId}:${propertyID}`, '_blank', 'noreferrer');
  }
  else if(name==="Tanda"){
    // window.open(`/tanda/callback?code=${urlCode}&state=${integrationId}:${propertyID}`, '_blank', 'noreferrer');
  }
  await setIsAuthDialog(false)
  },(2000))
}

useEffect(()=>{
  const ButtonTypeName=JSON.parse(localStorage.getItem('buttonNameInfo') as any) 
  const getXeroTenantData=async()=>{
    const data=({
      propertyId: propertyID,
      integrationId: integrationId,
      type: ButtonTypeName?.type,
      name: ButtonTypeName?.name,
      token: tokenData,
      externalId: tenantValue
    })
    console.log(data,"data")
    await saveXeroTenant({
      propertyId: propertyID,
      integrationId: integrationId,
      type: ButtonTypeName?.type,
      name: ButtonTypeName?.name,
      token: tokenData,
      externalId: tenantValue
    });
  }
  getXeroTenantData()
},[tokenData])
const handleCloseXeroDialog=()=>{
setAddAccountingSystemDialog(false)
}
const handleCloseXeroAuthDialog=()=>{
setIsAuthDialog(false)
}

const handleCloseAccountingSystemDialog = () => {
  setAccountingSystemDialog(false);
};

useEffect(() => {
const xeroconfiguration = searchParams.get('xeroconfiguration');
if (xeroconfiguration === 'true') {
  setOpenConfigDialog(true);
}
else{
  setOpenConfigDialog(false)
}
}, [location.search]); // triggers on URL change

const renderIntegrationSection = (title:string, type :string, imageSrc:string) => (
  <MDBox px={3}>
  <MDBox mt={1.5} mb={1.5} p={2} sx={{ background: "#FAFAFA" }}>
    <MDBox width="100%" display="flex" justifyContent="space-between">
      <MDBox>{title}</MDBox>
      <MDBox>
        {!integrations.some((integration) => integration.type === type) ? (
           <>
           <MDButton
               variant="gradient"
               color="dark"
               key="submitButton"
               onClick={()=>handleClickAccountingSystemDialog(type,title)}
               size="small"
             >
               Add
             </MDButton>
             <Dialog
               open={openAccountingSystemDialog}
               onClose={handleCloseAccountingSystemDialog}
               maxWidth="xs"
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description"
             >
               <DialogTitle id="alert-dialog-title">              
                 <MDBox>{`Add ${buttonName} System`}</MDBox>
               </DialogTitle>
               <DialogActions style={{justifyContent: 'center',gap: '40px'}}>
               {accountingSystemButtons.map((buttonName)=>{
                   return (
                     <>
                       <MDButton
                         variant="gradient"
                         color="dark"
                         onClick={()=>handleOpenXeroDialog(buttonName.url,buttonName)}
                         size="small"
                       >
                           {buttonName.name}
                       </MDButton>
                     </>
                   )
                 })}
               </DialogActions>
             </Dialog>
             <Dialog
               open={addAccountingSystemDialog}
               onClose={handleCloseXeroDialog}
               maxWidth="xs"
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description"
             >
               <DialogTitle id="alert-dialog-title">              
                 <MDBox>{`Connect to ${buttonName.name} using OAuth`}</MDBox>
               </DialogTitle>
               <DialogContent>
                 {`Step 1. To connect to ${buttonName.name}, click the button below go to ${buttonName.name} and login to approve Peak Revenue’s access your account.`}
                 <br></br>
                 <br></br>
                  Step 2. Once authorised, you will be redirected back to Peak Revenue and can then continue on to configure the information we require for this integration
               </DialogContent>
               <DialogActions style={{justifyContent: 'center',gap: '40px'}}>
                 <MDButton
                   variant="gradient"
                   color="dark"
                   onClick={()=>handleOpenXeroAuthDialog(buttonName.name)}
                   size="small"
                 >
                   {`Login to ${buttonName.name}`}
                 </MDButton>
               </DialogActions>
             </Dialog>
             <Dialog
               open={isAuthDialog}
               onClose={handleCloseXeroAuthDialog}
               maxWidth="sm"
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description"
             >
               <DialogTitle id="alert-dialog-title">              
                 <MDBox style={{paddingTop:'100px',paddingBottom: '160px',paddingLeft: '50px',paddingRight: '50px',fontSize: '24px', fontWeight: '700',color: '#000'}}>
                   Intergrations Authentication<br></br><br></br>
                 This is a simulated authentication process that will open a new  tab with the correct callback page.
                   
                   </MDBox>
               
               </DialogTitle>
               <DialogContent>
               </DialogContent>
             </Dialog>
         </>
        ) : (
          <MDButton
            variant="gradient"
            color="dark"
            key="submitButton"
            onClick={() => console.log(`Removing ${title} integration`)}
            size="small"
          >
            Remove
          </MDButton>
        )}
      </MDBox>
    </MDBox>
    {integrations
      .filter((integration) => integration.type === type)
      .map((integration) => (
        <MDBox
          key={integration.id}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={2}
        >
          {/* Display Integration Image and Name */}
          <MDBox display="flex" alignItems="center">
            <img
              src={imageSrc} // Set the correct image path
              alt={integration.name}
              style={{height: "50px", marginRight: "10px" }}
            />
            {/* <span>{integration.name}</span> */}
          </MDBox>

          {/* Configure Button */}
          <MDBox>
            <MDButton
              variant="gradient"
              color="dark"
              key={`editButton-${integration.id}`}
              onClick={handleClickOpenConfigDialog}
              size="small"
            >
              Configure
            </MDButton>
          </MDBox>
        </MDBox>
      ))}
  </MDBox>
</MDBox>
)

  return (
    <DashboardLayout>
        {openConfigDialog ? 
      <>
      <XeroConfiguration />
      </>:
      <MDBox py={1} mb={2} >
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
              <MDBox p={3}>
                <MDBox>
                  <MDTypography variant="h5" fontWeight="bold">
                    Property Details
                  </MDTypography>
                  <MDBox mt={1.625}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormField
                          key="propertyName"
                          type="text"
                          label="Property Name"
                          name="propertyName"
                          value={formik.values.propertyName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MDBox mt={-1.625}>
                          <FormField
                            key="propertyDescription"
                            type="text"
                            label="Property Description"
                            name="propertyDescription"
                            value={formik.values.propertyDescription}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox mt={-1.625}>
                          <FormField
                            key="abn"
                            type="text"
                            label="ABN"
                            name="abn"
                            value={formik.values.abn}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox mt={-1.625}>
                          <FormField
                            key="rooms"
                            type="text"
                            label="Number of Rooms"
                            name="rooms"
                            value={formik.values.rooms}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox mt={-1.625}>
                          <FormField
                            key="dataDay"
                            type="text"
                            label="Day to Refresh Data"
                            name="dataDay"
                            value={formik.values.dataDay}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12}>
                        <MDBox mt={-1.625}>
                          <FormControlLabel
                            control={      
                            <Switch
                              name="propertyEnabled"
                              checked={formik.values.propertyEnabled}
                              onChange={formik.handleChange}
                              key="propertyEnabled"
                            />}
                            label="Enabled"
                            color="primary"
                            key="propertyEnabledControl"
                          />
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                    <MDButton
                      disabled={false}
                      type="submit"
                      variant="gradient"
                      color="dark"
                      key="submitButton"
                      size="small"
                    >
                      {buttonText}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
              </form>   
              </FormikProvider>
            </Card>
          </Grid>

          <Grid xs={12} lg={8} py={3}>
            <Card sx={{ height: "100%" }} id="Integrations">
              <MDBox pt={3} pr={3} pl={3} lineHeight={1}>
                <MDBox mb={1}>
                  <MDTypography variant="h5">Integrations</MDTypography>
                </MDBox>
              </MDBox>

              {/* Accounting Integrations Section */}
              {renderIntegrationSection("Accounting", "acc", MyobImage)}

              {/* PMS Integrations Section */}
              {renderIntegrationSection("PMS", "pms", logoRMS)}

              {/* Timesheets Integrations Section */}
              {renderIntegrationSection("Timesheets", "ts", logoMyob)}
            </Card>
          </Grid>
            {/* <Card id="accounts" >
              <MDBox p={3} lineHeight={1}>
                <MDBox mb={1}>
                  <MDTypography variant="h5">Accounts</MDTypography>
                </MDBox>
                <MDTypography variant="button" color="text">
                  The following integrations are available for configuration
                </MDTypography>
              </MDBox>
                            
              <MDBox pt={2} pb={3} px={3}>
                
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  
                  <MDBox display="flex" alignItems="center">
                    <MDAvatar src={logoMyob} alt="MYOB logo" variant="rounded" />
                  </MDBox>
     
                  <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    width={{ xs: "100%", sm: "auto" }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <MDBox lineHeight={0} mx={2}>
                      <MDTypography variant="button" color="text">
                        {myobEnabled ? "Enabled" : "Disabled"}
                      </MDTypography>
                    </MDBox>
                    <MDBox mr={1}>
                      <Switch checked={myobEnabled} onChange={handleMYOBChange} />
                    </MDBox>  
                  </MDBox>    
                </MDBox>
                <MDBox ml={2} pl={1} pt={2} lineHeight={1}>
                  <MDTypography variant="button" color="text">
                    To authenticate with MYOB, cllick the button below and login into your MYOB Account with your username and password then 
                    follow the instructions to allow PeakRev access to your Company File.
                  </MDTypography>
                  
                  <MDBox
                    bgColor={"grey-100"}
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    my={3}
                    py={1}
                    pl={{ xs: 1, sm: 2 }}
                    pr={1}
                  >
                      {!isObjectEmpty(MYOBintegration) ? (
                        <MDTypography variant="button" fontWeight="light" color="text">
                          Your MYOB Account is Connected to <b>{MYOBintegration.name}</b>
                        </MDTypography>
                      ) : (
                        <MDTypography variant="button" fontWeight="medium" color="text">
                          Connect account
                        </MDTypography>
                      )}

                    <MDBox
                      display="flex"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <MDButton 
                        variant="gradient" 
                        color="dark" 
                        size="small"
                        onClick={() => {
                          window.open(`${myobAuth}`, '_blank', 'noreferrer');
                        }}
                        >
                        {!isObjectEmpty(MYOBintegration) ? (
                          "Update"
                        ) : (
                          "Authenticate"
                        )}
                      </MDButton>
                    </MDBox>
                  </MDBox>  
                </MDBox>
                   
                <Divider />
                <Grid container>
                  <Grid item lg={12}>
                      <Grid container>
                        <Grid item lg={1} textAlign={"left"}>
                          <MDAvatar src={logoRMS} alt="RMS logo" variant="rounded" />
                        </Grid>
                        <Grid item lg={1} textAlign={"left"} py={1.3}>
                          <MDTypography variant="h5" fontWeight="medium">
                            RMS
                          </MDTypography>
                        </Grid>
                        <Grid item lg={9} textAlign={"right"} py={1.3}>
                          <MDTypography variant="button" color="text">
                            {{rmsEnabled} ? "Enabled" : "Disabled"}
                          </MDTypography>
                        </Grid>
                        <Grid item lg={1} textAlign={"right"} py={1.3}>
                          <MDTypography variant="button" color="text">
                            <Switch checked={true} onChange={handleRMSChange} />
                          </MDTypography>
                        </Grid>
                        <Grid item lg={12} textAlign={"left"} px={3}>
                          <MDBox lineHeight={1}>
                            <MDTypography variant="button" color="text">
                            To authenticate with RMS click the Authenticate button,  
                            complete the login procedure and select the property you wish 
                            to associate with Peak Rev.
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid container>
                          <Grid item textAlign={"left"} px={3} lg={10}>
                            <MDBox
                              bgColor={"grey-100"}
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems={{ xs: "flex-start", sm: "center" }}
                              flexDirection={{ xs: "column", sm: "row" }}
                              my={3}
                              py={1}
                              pl={{ xs: 1, sm: 2 }}
                              pr={1}
                            >
                            {!isObjectEmpty(RMSintegration) ? (
                              <MDTypography variant="button" fontWeight="light" color="text">
                                Your RMS Account is Connected
                              </MDTypography>
                            ) : (
                              <MDTypography variant="button" fontWeight="medium" color="text">
                                Your Connect has not been connected
                              </MDTypography>
                            )}
                            </MDBox>
                          </Grid>
                          <Grid item textAlign={"left"} py={3} lg={2}>
                          <MDButton 
                            variant="gradient" 
                            color="dark" 
                            size="small"
                            onClick={() => {
                              authRMS();
                            }}
                            >
                            {!isObjectEmpty(RMSintegration) ? (
                              "Update"
                            ) : (
                              "Authenticate"
                            )}
                          </MDButton>
                          </Grid>
                        </Grid>
                      </Grid>
                  </Grid>
                  <Grid item lg={12}>
                    
                  </Grid>
                </Grid>
                
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12} lg={8} py={0}>
            <Card id="config" >
              <MDBox p={3} lineHeight={1}>
                <MDBox mb={1}>
                  <MDTypography variant="h5">Property Configuration</MDTypography>
                </MDBox>
                <MDTypography variant="button" color="text">
                  Update the values below for custom configuration of Components for this property
                </MDTypography>
              </MDBox>
              <form onSubmit={handleSubmit(onSubmit)}>
              <MDBox pt={2} pb={3} px={3}>
                <Grid container>
                  <Grid item lg={12}>
                    <Grid container>
                      <Grid item lg={12}>
                        <MDBox lineHeight={1}>
                          <MDTypography variant="button" color="text">
                            {componentData!=null && componentData.map((item:any) => (
                              //Display the component name if it has a propertyConfig
                              item.propertyConfig.length>0 && (
                                <Grid container>
                                  <Grid item lg={12}>
                                    <Box mt={2} border={1} p={2} borderRadius={5} borderColor={"lightBlue"}>
                                      <Grid container>
                                        <Grid item lg={6}>
                                          <MDTypography variant="h6" color="text" >
                                            {item.type} {item.name}
                                          </MDTypography>
                                        </Grid>
                                        <Grid item lg={6}>
                                          <Box alignContent={"right"}>
                                          <MDButton
                                            color="warning" 
                                            size="medium"
                                            onClick={() => refreshHistory(item.uid)}
                                            style={{ float: 'right' }}>
                                             {isLoading ? <CircularProgress size={24} /> : historyButtonText}
                                          </MDButton>
                                          </Box>
                                         
                                        </Grid>
                                        <Grid item lg={12}>
                                          {item.propertyConfig?.map((configItem:string, key: number) => (
                                            <Grid container>
                                              <Grid item lg={4} py={1}>
                                                <MDTypography variant="button" color="text" >
                                                  {configItem}
                                                </MDTypography>
                                                <TextField
                                                  fullWidth={true}
                                                  type="text" variant="standard" {...register(`${item.uid}-${configItem}`, { required: true })} 
                                                  />
                                              </Grid>
                                            </Grid>
                                          ))}
                                        </Grid>
                                      </Grid>                                    
                                    </Box>
                                  </Grid>
                                </Grid>
                              ) 
                            ))}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
              
              <MDBox p={3} lineHeight={1}>
                <MDBox mb={1}>
                  <MDButton
                      disabled={false}
                      type="submit"
                      variant="gradient"
                      color="dark"
                      key="submitButton"
                    >
                      {configButtonText}
                    </MDButton>
                </MDBox>
              </MDBox>
              </form>
            </Card>
          </Grid> */}
          
        </Grid> 
      </MDBox>}
      <Footer />
    </DashboardLayout>
  );
}

export default EditProperty;

import MDTypography from "components/MDTypography";
import { Link, useNavigate } from "react-router-dom";
import CoverLayout from "./components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { useContext } from "react";
import { AppContext, UserData } from 'lib/appContext';
import { signOut as invalidateSession } from "auth/authService";

const SignOut: React.FC = () => {

  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const userData: UserData | undefined = appContext?.userData;

  async function signOut() {
    try {
      await invalidateSession();
      sessionStorage.clear();
      // navigate('/authentication/sign-in');

    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

signOut();

return (
<CoverLayout image={bgImage}>
    <Card>
        <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        p={3}
        mb={1}
        textAlign="center">
        <h1 style={{ fontSize: "22px", fontWeight: 800 }}>
            Sign Out
        </h1>
    </MDBox>
    <MDBox
    textAlign="center">
    <MDTypography display="block" variant="button" color="black" my={1}>
    You have signed out.  
    </MDTypography>
    <MDTypography display="block" variant="button" color="black" my={1}>
    
    Click <MDTypography
                component={Link}
                to="/authentication/sign-in"
                variant="text"
                color="info"
                fontWeight="medium"
                textGradient
                >here
                </MDTypography> to login in again.
        </MDTypography>
    </MDBox>
</Card>
</CoverLayout>)
}

export default SignOut;